import React, { useState, useRef } from 'react';
import './Collapsible.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'

function Collapsible(props) {
    const [isExpended, setExpended] = useState(false);
    const [isChanged, setChanged] = useState(false);

    const bodyRef = useRef();

    const handleExpension = () => {
        setChanged(true);
        setExpended(!isExpended);
    };
    if (props.expended !== undefined && props.expended !== isExpended && !isChanged)
        setExpended(props.expended || false);
        

    return (
        <div className='Collapsible'>
            <div className='CollapsibleHeader'
                style={{ color: 'var(--yellow)' }} onClick={handleExpension}>
                <div className='CollapsibleIcon' >
                    <FontAwesomeIcon icon={isExpended ? faChevronDown : faChevronRight} />
                </div>
                <div className='CollapsibleTitle'>{props.title}</div>
                <div className='CollapsibleBar'></div>
            </div>
            <div 
            className='CollapsibleBody' 
            ref={bodyRef}
            style={{height: isExpended && bodyRef.current? bodyRef.current.scrollHeight + 'px' : '0px' }}
            >
            {props.children}
            </div>
        </div>
    );
}

export default Collapsible;