import React, { useState } from 'react';
import './NavBar.css';
import { Link } from "react-router-dom";
import LanguageChanger from '../languageChanger/LanguageChanger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';

export default function Navbar() {

    const { t } = useTranslation();

    const [isNavExpended, setNavExpended] = useState(false);

    const handleNavCollapse = () => setNavExpended(!isNavExpended);
    const navMenu = (
        <ul className='nav-menu'>
            <li className='nav-item'>
                <Link
                    to='https://github.com/muratcancicek'
                    className='nav-links'
                    target='_blank'
                >
                    GitHub
                </Link>
            </li>
            <li className='nav-item'>
                <Link
                    to='Muratcan_Cicek_Resume'
                    className='nav-links'
                    target='_blank'
                >
                    Resume
                </Link>
            </li>
            <li className='nav-item'>
                <Link
                    to='https://www.linkedin.com/in/muratcancicek/'
                    className='nav-links'
                    target='_blank'
                >
                    LinkedIn
                </Link>
            </li>
            <li className='nav-item'>
                <Link
                    to='https://scholar.google.com/citations?user=YtUD7mMAAAAJ&hl=en'
                    className='nav-links'
                    target='_blank'
                >
                    Scholar
                </Link>
            </li>
            <li className='nav-item'>
                <Link
                    to='https://blog.muratcancicek.com/'
                    className='nav-links'
                    target='_blank'
                >
                    Blog
                </Link>
            </li>
        </ul>
    );

    const navMenuWide = (
        <nav className='navbar navbarWide'>
            <div className="topLeft">
                <Link to='/' className='nav-links' >
                    Muratcan Çiçek
                </Link>
            </div>

            <div className="center">
                {navMenu}
            </div>
            <div className="topRight">
                <LanguageChanger />
            </div>
        </nav>
    );

    const navMenuTight = (
        <nav className='navbar navbarTight'>
            <div className="up">
                <div className="topLeft">
                    <div className='nav-button' onClick={handleNavCollapse}>
                        <FontAwesomeIcon icon={isNavExpended ? faTimes : faBars} className='nav-icon' />
                    </div>
                </div>

                <div className="center">
                    <Link to='/' className='nav-links' >
                        Muratcan Çiçek
                    </Link>
                </div>
                <div className="topRight">
                    <LanguageChanger />
                </div>
            </div>
            <div className="down">
                {isNavExpended && navMenu}
            </div>
        </nav>
    );
    return (
        <div className='navbar'>
            {navMenuWide}
            {navMenuTight}
        </div>
    );
}