import './MainContainer.css';
import Navbar from '../navBar/NavBar';
import Footer from '../footer/Footer';

function MainContainer(props) {
  return (
    <div className="MainContainer">
      <Navbar />
      <div className="MainContent">
        {props.children}
      </div>
      <Footer />
    </div>
  );
}

export default MainContainer;
