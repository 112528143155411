import Collapsible from "../../components/collapsible/Collapsible";

import { useTranslation } from 'react-i18next';

function AboutMe() {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === 'en';

    const aboutMeEnText = (
        <p>
            I develop mobile applications that apply Deep Learning techniques
            to assist people with disabilities.
            <br /> <br />
            I was born in the early 90s. I grew up with DMX songs and his movies.
            Then, I met Lil Wayne. By the inspiration of these two, I started 
            hustling and grinding at my school years. I was the top graduate in
            every school I attended. Like Chamillionaire, I was in interested in
            tech business and pursued my B.S. in Computer Engineering. My career
            took me to the West Coast where I worked hard, and played hard. I still
            live by California Dreamin'.
            <br /> <br />
            By the way, I am a person with Cerebral Palsy. But, it is not a big deal.
            <br /> <br />
            <b>GALATASARAY!</b>
        </p>
    );

    const aboutMeTrText = (
        <p>
            Engelli bireylerinlerin hayatlarını kolaylaştırmak amacıyla 
            Derin Öğrenme tekniklerini kullanan mobil uygulamalar geliştiriyorum.
            <br /> <br />
            90'ların başında doğdum. DMX şarkıları ve filmleriyle büyüdüm. Sonra
            Lil Wayne ile tanıştım. Hip-Hop kültürünün ilhamıyla okul yıllarımda
            çok çalıştım ve okuduğum her okulu birincilikle bitirdim. Yine bir
            rapçi olan Chamillionaire gibi, teknoloji işlerine ilgi duydum ve
            Bilgisayar Mühendisliği lisansımı tamamladım. Kariyerim beni
            Kaliforniya'ya, Silikon Vadisi'ne götürdü, orada da çok çalıştım ve
            çok eğlendim. Hala Kaliforniya Rüyası yaşıyorum.
            <br /> <br />
            Bu arada, ben bir Spastik Engelli bireyim. Ama, bu çok da önemli değil.
            <br /> <br />
            <b>GALATASARAY</b> taraftarıyım, evet!
        </p>
    );

    return (
        <Collapsible title={t('landing.aboutMe.title')} expended={false}>
            <div className="AboutMe">
                <div className="AboutMeText">
                    {isEnglish ? aboutMeEnText : aboutMeTrText}
                </div>
            </div>
        </Collapsible>
    );
}

export default AboutMe;