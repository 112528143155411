import Collapsible from "../../components/collapsible/Collapsible";

import { useTranslation } from 'react-i18next';

function Skills() {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === 'en';
    const space = ' ';
    return (
        <Collapsible title={t('landing.skills.title')} expended={false}>
            <div className="Skills">
                <div className="SkillsText">
                    <p>
                        <b>{t('landing.skills.programmingLanguages')}: </b>
                        Python, JavaScript, Dart, Java, HTML, CSS, C, C++,
                        C#, TypeScript
                        <br />
                        <b>{t('landing.skills.frameworks')}: </b> 
                        React, Flutter, React Native, PyTorch,
                        TensorFlow, Keras, OpenCV, Firebase, AWS Amplify,
                        Supabase, Django
                        <br />
                        <b>{t('landing.skills.tools')}: </b> 
                        Git, Amazon Web Services, Google Cloud Platform,
                        ChatGPT, GitHub Co-pilot, Jupyter Notebook, 
                        Visual Studio Code, Android Studio, MidJourney, 
                        Stable Diffusion, Figma, Miro, Asana
                        <br />
                        <b>{t('landing.skills.softSkills')}: </b> 
                        {t('landing.skills.analyticalThinking')},{space}
                        {t('landing.skills.problemSolving')},{space}
                        {t('landing.skills.fastLearning')},{space}
                        {t('landing.skills.communication')},{space}
                        {t('landing.skills.teamwork')},{space}
                        {t('landing.skills.taskScheduling')},{space}
                        {t('landing.skills.teamBuilding')},{space}
                        {t('landing.skills.leadership')},{space}
                        {t('landing.skills.adapting')}

                    </p>
                </div>
            </div>
        </Collapsible>
    );
}

export default Skills;