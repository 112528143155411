import "./Joke.css";
import JokeImg from '../../assets/images/joke.png';

import { useTranslation } from 'react-i18next';

function Joke(probs) {
  const { t } = useTranslation();

  const jokeTr = 'Biz de seni bekliyorduk şansız bedevi :)';
    const jokeEn = 'When you are a fool, everyday is April 1st! :)';
    let joke = jokeEn;
    if (probs.language) {
        joke = probs.language == 'tr' ? jokeTr : jokeEn;
    }
  return (
      <div className="Joke">
        <h1 className="JokeTitle">{joke}</h1>
        <img className="JokeImg" src={JokeImg} />

      </div>
  );
}

export default Joke;