import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faYoutube, faFacebook, faTwitter, faWordpress }
  from '@fortawesome/free-brands-svg-icons'
import OnlyFans from '../../assets/icons/onlyfans.svg';

import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();

  const joke = i18n.language === 'en' ?
    process.env.PUBLIC_URL + '/joke_en' :
    process.env.PUBLIC_URL + '/joke_tr';

  const buildSocialMediaIcon = (icon, link) => {
    return (
      <Link to={link} className='FooterSocialIconLink' target='_blank' >
        <FontAwesomeIcon className="FooterSocialIcon" icon={icon} />
      </Link>
    );
  }
  return (
    <div className="Footer">
      <div className="FooterCopyright">
        {t('footer.copyRight')}
      </div>
      <div className="FooterEmail">
        <FontAwesomeIcon className="FooterSocialIcon" icon={faEnvelope} />
        <div className="FooterEmailText">
          muratcancicek0@gmail.com
        </div>
      </div>
      <div className="FooterSocialMedia">
        {buildSocialMediaIcon(faInstagram, 'https://www.instagram.com/muratcan_cicek0/')}
        {buildSocialMediaIcon(faYoutube, 'https://www.youtube.com/channel/UC8Ti5Apa4nZyS3ITSZ5pHnA')}
        {buildSocialMediaIcon(faFacebook, 'https://www.facebook.com/muratcan.cicek21/')}
        {buildSocialMediaIcon(faTwitter, 'https://twitter.com/MuRaTcan_cicek')}
        {buildSocialMediaIcon(faWordpress, 'https://muratcancicek.wordpress.com/')}
        <Link to={joke} className='FooterSocialIconLink' target='_blank' >
          <img className="FooterSocialIcon" src={OnlyFans} />
        </Link>
      </div>
    </div>
  );
}

export default Footer;