import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Landing from './pages/landing/Landing';
import ResumePDF from './components/navBar/ResumePDF';
import Joke from './pages/joke/Joke';

function App() {
  return (
    console.log(process.env.PUBLIC_URL),
    <div className="App">
      <Router>
        <Routes>
          <Route path={process.env.PUBLIC_URL+"/"} element={<Landing />} />
          <Route path={process.env.PUBLIC_URL+"/Muratcan_Cicek_Resume"} element={<ResumePDF />} />
          <Route path={process.env.PUBLIC_URL+"/joke_en"} element={<Joke language='en' />} />
          <Route path={process.env.PUBLIC_URL+"/joke_tr"} element={<Joke language='tr' />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
