import Collapsible from "../../components/collapsible/Collapsible";

import { useTranslation } from 'react-i18next';

function Experience() {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === 'en';

    const eBayLink = (
        <a href="https://www.ebayinc.com/stories/news/ebay-open-sources-technology-that-uses-head-motion-to-navigate-user-interface-on-iphone-x/" target="_blank">
            eBay
        </a>
    );

    const headGazeLibLink = (
        <a href="https://github.com/eBay/HeadGazeLib" target="_blank">HeadGazeLib</a>
    );

    const fastCompanyLink = (
        <a href="https://www.fastcompany.com/90325685/this-iphone-app-makes-web-navigation-truly-hands-free" target="_blank">
            {isEnglish ? 'the World Changing Ideas in 2019' : '2019 yılında Dünyayı Değiştiren Fikirler'}
        </a>
    );

    const googlePaperLink = (
        <a href="https://dl.acm.org/doi/abs/10.1145/3373625.3416994" target="_blank">
           {isEnglish ? 'our findings' : 'akademik çalışmamızı'}
        </a>
    );

    const assets2020Link = (
        <a href="https://assets20.sigaccess.org/accepted_papers.html" target="_blank">
            {isEnglish ? 'the ACM ASSETS 2020 conference' : 'ACM ASSETS 2020 konferansında'}
        </a>
    );

    const euphoniaProjectLink = (
        <a href="https://assets20.sigaccess.org/accepted_papers.html" target="_blank">
            Project Euphonia
        </a>
    );

    const euphoniaLink = (
        <a href="https://blog.google/outreach-initiatives/accessibility/project-euphonia-1000-hours-speech-recordings" target="_blank">
            {isEnglish ? 'recording process' : 'ses kaydı sürecindeki'}
        </a>
    );


    const yeterlyLink = (
        <a href="https://www.yeterly.com/" target="_blank">Yeterly</a>
    );

    const experienceEnText = (
        <p>
            In 2017, I started pursuing my Ph.D. in Deep Learning, specifically
            on Computer Vision models for face-tracking at UCSC Computer Vision
            Lab in Santa Cruz, CA. I proposed a novel control mechanism for
            computer and mobile devices that utilize head movements as user input.
            <br /> <br />
            In 2018, I worked at {eBayLink} to develop a mobile application
            for iOS that allows users to browse products just by moving their
            heads. The {headGazeLibLink} project  was open-sourced and recognized
            as one of {fastCompanyLink} by Fast Company.
            <br /> <br />
            In 2019, I moved to Google, and worked in two different teams. First,
            I led a research project in Human-Computer Interaction that measures
            the user's pointing accuracy on mobile devices by head movements. I
            developed an open-source mobile application with Flutter and TF Lite
            for this research. We published {googlePaperLink} at {assets2020Link}.
            <br /> <br />
            In 2020, I joined the {euphoniaProjectLink} team as a Machine Learning
            Engineer. The Google AI project aims to improve speech recognition for
            people with speech impairments. I studied advanced data selection
            methods where the sampling data was limited. I also analyzed the user
            experience in the {euphoniaLink} during the project.
            <br /> <br />
            In 2021, I worked at Amazon Web Services in Berlin, Germany, as a
            Software Development Engineer and joined a team that improves the
            billing experience for AWS customers.
            <br /> <br />
            In 2022, I co-founded a startup called {yeterlyLink} that builds a
            catalog of assistive technologies for people with disabilities. We
            provide personalized recommendations for people with disabilities 
            to find the best assistive technology. I developed the mobile 
            application with Flutter and the backend.
            <br /> <br />
            I am currently looking for a full-time position and available to
            work in most areas of the world.
        </p>
    );

    const experienceTrText = (
        <p>
            2017'de Derin Öğrenme üzerine doktora yapmaya başladım, özellikle
            yüz takibi için  bilgisayarla görü modelleri üzerine çalıştım.
            Kullanıcı girişi olarak baş hareketlerini kullanan bilgisayar ve
            mobil cihazlar için yeni bir kontrol mekanizması öneriyorum.
            <br /> <br />
            2018'de {eBayLink} bünyesinde, kullanıcıların sadece başlarını
            hareket ettirerek ürünlere göz atmasına olanak sağlayan bir iOS
            mobil uygulaması geliştirdim. HeadGazeLib projesi açık kaynak
            olarak paylaşıldı ve 2019'da Fast Company 
            tarafından {fastCompanyLink} arasında kabul edildi.
            <br /> <br />
            2019'da ise Google bünyesine geçip orada iki farklı takımda
            çalıştım. Öncelikle, kullanıcının mobil cihazlarda baş 
            hareketleriyle gösterme doğruluğunu ölçen bir İnsan-Bilgisayar
            Etkileşimi araştırma projesini yönettim. Bu araştırma için
            Flutter ve TF Lite  ile açık kaynak bir mobil uygulama
            geliştirdim. {googlePaperLink} {assets2020Link} yayınladık.
            <br /> <br />
            2020'de, {euphoniaProjectLink} ekibine Makine Öğrenmesi
            Mühendisi olarak katıldım. Google AI projesi, konuşma bozukluğu
            olan kişiler için konuşma tanıma özelliklerini iyileştirmeyi
            hedefliyor. Örneklem verisinin sınırlı olduğu durumlarda
            gelişmiş veri seçim metotları üzerinde çalıştım. Proje boyunca
            ayrıca {euphoniaLink} kullanıcı deneyimini de analiz ettim.
            <br /> <br />
            2021 yılı boyunca Berlin, Almanya'da Amazon Web Services'te
            Yazılım Geliştirme Mühendisi olarak çalıştım ve AWS müşterileri
            için faturalandırma deneyimini geliştiren bir ekibe katıldım.
            <br /> <br />
            2022'de, engelli bireyler için yardımcı teknolojiler kataloğu
            oluşturan {yeterlyLink} adında bir girişim kurdumm. Engelli
            bireylerin kendi ihtiyaçlarına yönelik en doğru yardımcı 
            teknolojiyi bulmaları adına kişiselleştirilmiş destek sağlıyoruz.
            Bu hizmet için gerekli Mobil uygulamayı Flutter ile ben
            geliştirdim. Ayrıca backend'in inşasını da üstlendim. 
            <br /> <br />
            Şu anda tam zamanlı bir iş arıyorum ve dünyanın birçok yerinde
            çalışmaya hazırım.
        </p>
    );

    return (
        <Collapsible title={t('landing.experience.title')} expended={false}>
            <div className="Experience">
                <div className="ExperienceText">
                    {isEnglish ? experienceEnText : experienceTrText}
                </div>
            </div>
        </Collapsible>
    );
}

export default Experience;