import ResumeFile from '../../assets/pdfs/Muratcan_Cicek_Resume.pdf';
import './ResumePDF.css';

function ResumePDF() {
  return (
    <div className='pdfContainer' >
    <object data={ResumeFile} type="application/pdf" width="100%" height="100%">
  </object>
    </div>
  );
}

export default ResumePDF;
