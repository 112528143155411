import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageChanger() {
    const { i18n } = useTranslation();

    const handleChangeLanguage = () => {
        const currentLanguage = i18n.language;
        const newLanguage = currentLanguage === 'tr' ? 'en' : 'tr';
        i18n.changeLanguage(newLanguage);
    };

    const currentLanguage = i18n.language;

    return (
        <div
            className="languageChanger"
            onClick={handleChangeLanguage}
            style={{ cursor: 'pointer' }}
        >
            {currentLanguage === 'tr' ? 'EN' : 'TR'}
        </div>
    );
}

export default LanguageChanger;