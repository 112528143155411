import "./LandingHeader.css";
import ProfileImg from "../../assets/images/ProfileImg.jpg";
import ebayLogo from "../../assets/images/EbayLogo.png";
import googleLogo from "../../assets/images/GoogleLogo.png";
import awsLogo from "../../assets/images/AWSLogo.png";

import { useTranslation } from 'react-i18next';

function LandingHeader() {
  const { t } = useTranslation();

  return (
    <div className="HeaderContainer">
      <img src={ProfileImg} alt={t('landing.image')} className="profileImg" />
      <div className="HeaderTextContainer" style={{ color: 'var(--white)' }}>
        {t('landing.header.title')}
        <div className="HatsContainer">
          <div style={{ color: 'var(--white)' }}>{t('landing.header.machineLearning')}</div>
          <div style={{ color: 'var(--red)' }}>{t('landing.header.mobile')}</div>
          <div style={{ color: 'var(--yellow)' }}>{t('landing.header.web')}</div>
        </div>
        <div className="SchoolContainer">
          <div style={{ color: 'var(--white)' }}>{t('landing.header.phD')}</div>
          <div className="School">
            <div style={{ color: 'var(--red)' }}>{t('landing.header.major')}</div>
            <div style={{ color: 'var(--yellow)' }}>{t('landing.header.school')}</div>
          </div>
        </div>
        <div className="StudiesContainer">
          <div style={{ color: 'var(--white)' }}>{t('landing.header.computerVision')}</div>
          <div style={{ color: 'var(--red)' }}>{t('landing.header.assistiveTechnology')}</div>
          <div style={{ color: 'var(--yellow)' }}>{t('landing.header.hci')}</div>
        </div>
        <div className="HeaderBottom">
          <div className="Scholarship">
            <div style={{ color: 'var(--white)' }}>{t('landing.header.2020Google')}</div>
            <div style={{ color: 'var(--red)' }}>{t('landing.header.phDFellow')}</div>
          </div>
          <div className="CompaniesContainer">
            <div style={{ color: 'var(--yellow)' }}>{t('landing.header.previouslyWorkedAt')}</div>
            <div className="CompanyLogos">
              <img src={ebayLogo} alt={t('landing.ebayLogo')} className="CompanyLogo" />
              <img src={googleLogo} alt={t('landing.googleLogo')} className="CompanyLogo" />
              <img src={awsLogo} alt={t('landing.awsLogo')} className="CompanyLogo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingHeader;