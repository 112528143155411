import "./Landing.css";
import MainContainer from "../../components/mainContainer/MainContainer";
import LandingHeader from "./LandingHeader";
import AboutMe from "./AboutMe";
import Skills from "./Skills";
import Experience from "./Experience";

import { useTranslation } from 'react-i18next';

function Landing() {
  const { t } = useTranslation();

  return (
    <MainContainer >
      <div className="Landing">
        <LandingHeader />
        <div className="BodyContainer">
          <AboutMe />
          <Skills />
          <Experience />
        </div>
      </div>
    </MainContainer>
  );
}

export default Landing;