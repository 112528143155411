import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationTR from './locales/tr.json';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      tr: {
        translation: translationTR
      },
      // Add more languages as needed
    },
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false,
    }
  });
// define the function outside of component scope
const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
export default i18n;